export const RESOURCE_LIST_REQUEST = 'RESOURCE_LIST_REQUEST'
export const RESOURCE_LIST_SUCCESS = 'RESOURCE_LIST_SUCCESS'
export const RESOURCE_LIST_FAIL = 'RESOURCE_LIST_FAIL'

export const RESOURCE_SEARCH_REQUEST = 'RESOURCE_SEARCH_REQUEST'
export const RESOURCE_SEARCH_SUCCESS = 'RESOURCE_SEARCH_SUCCESS'
export const RESOURCE_SEARCH_FAIL = 'RESOURCE_SEARCH_FAIL'

export const RESOURCE_CREATE_REQUEST = 'RESOURCE_CREATE_REQUEST'
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS'
export const RESOURCE_CREATE_FAIL = 'RESOURCE_CREATE_FAIL'
export const RESOURCE_CREATE_RESET = 'RESOURCE_CREATE_RESET'
