export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST'
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS'
export const QUESTION_UPDATE_FAIL = 'QUESTION_UPDATE_FAIL'

export const QUESTION_DELETE_REQUEST = 'QUESTION_DELETE_REQUEST'
export const QUESTION_DELETE_SUCCESS = 'QUESTION_DELETE_SUCCESS'
export const QUESTION_DELETE_FAIL = 'QUESTION_DELETE_FAIL'

export const QUESTION_LIST_REQUEST = 'QUESTION_LIST_REQUEST'
export const QUESTION_LIST_SUCCESS = 'QUESTION_LIST_SUCCESS'
export const QUESTION_LIST_FAIL = 'QUESTION_LIST_FAIL'
