export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST'
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS'
export const NEWS_LIST_FAIL = 'NEWS_LIST_FAIL'

export const NEWS_SEARCH_REQUEST = 'NEWS_SEARCH_REQUEST'
export const NEWS_SEARCH_SUCCESS = 'NEWS_SEARCH_SUCCESS'
export const NEWS_SEARCH_FAIL = 'NEWS_SEARCH_FAIL'

export const NEWS_CREATE_REQUEST = 'NEWS_CREATE_REQUEST'
export const NEWS_CREATE_SUCCESS = 'NEWS_CREATE_SUCCESS'
export const NEWS_CREATE_FAIL = 'NEWS_CREATE_FAIL'
export const NEWS_CREATE_RESET = 'NEWS_CREATE_RESET'

export const NEWS_DELETE_REQUEST = 'NEWS_DELETE_REQUEST'
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS'
export const NEWS_DELETE_FAIL = 'NEWS_DELETE_FAIL'

export const NESW_SAVE_TEXT_DETAIL = 'NESW_SAVE_TEXT_DETAIL'
export const NESW_SAVE_IMAGE_DETAIL = 'NESW_SAVE_IMAGE_DETAIL'
export const NESW_SAVE_VIDEO_DETAIL = 'NESW_SAVE_VIDEO_DETAIL'
export const NEWS_CLEAR = 'NEWS_CLEAR'

export const NEWS_UPDATE_REQUEST = 'NEWS_UPDATE_REQUSET'
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS'
export const NEWS_UPDATE_FAIL = 'NEWS_UPDATE_FAIL'
