export const ACCESS_TOKEN_FAIL = 'ACCESS_TOKEN_FAIL'
export const ACCESS_TOKEN_REQUEST = 'ACCESS_TOKEN_REQUEST'
export const ACCESS_TOKEN_SUCCESS = 'ACCESS_TOKEN_SUCCESS'

export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_CONFIRM_CODE_REQUEST = 'USER_CONFIRM_CODE_REQUEST'
export const USER_CONFIRM_CODE_SUCCESS = 'USER_CONFIRM_CODE_SUCCESS'
export const USER_CONFIRM_CODE_FAIL = 'USER_CONFIRM_CODE_FAIL'

export const USER_RESEND_CODE_REQUEST = 'USER_RESEND_CODE_REQUEST'
export const USER_RESEND_CODE_SUCCESS = 'USER_RESEND_CODE_SUCCESS'
export const USER_RESEND_CODE_FAIL = 'USER_RESEND_CODE_FAIL'

export const USER_ATTR_CONFIRM_CODE_REQUEST = 'USER_ATTR_CONFIRM_CODE_REQUEST'
export const USER_ATTR_CONFIRM_CODE_SUCCESS = 'USER_ATTR_CONFIRM_CODE_SUCCESS'
export const USER_ATTR_CONFIRM_CODE_FAIL = 'USER_ATTR_CONFIRM_CODE_FAIL'

export const USER_ATTR_RESEND_CODE_REQUEST = 'USER_ATTR_RESEND_CODE_REQUEST'
export const USER_ATTR_RESEND_CODE_SUCCESS = 'USER_ATTR_RESEND_CODE_SUCCESS'
export const USER_ATTR_RESEND_CODE_FAIL = 'USER_ATTR_RESEND_CODE_FAIL'

export const USER_FORGOT_PWD_CONFIRM_CODE_REQUEST = 'USER_FORGOT_PWD_CONFIRM_CODE_REQUEST'

export const USER_FORGOT_PWD_CODE_SUCCESS = 'USER_FORGOT_PWD_CODE_SUCCESS'
export const USER_FORGOT_PWD_CODE_FAIL = 'USER_FORGOT_PWD_CODE_FAIL'
export const USER_FORGOT_PWD_CODE_RESET = 'USER_FORGOT_PWD_CODE_RESET'

export const USER_FORGOT_PWD_RESEND_CODE_REQUEST = 'USER_FORGOT_PWD_RESEND_CODE_REQUEST'

export const USER_PASSWORD_CHANGE_REQUEST = 'USER_PASSWORD_CHANGE_REQUEST'
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS'
export const USER_PASSWORD_CHANGE_FAIL = 'USER_PASSWORD_CHANGE_FAIL'
export const USER_PASSWORD_CHANGE_RESET = 'USER_PASSWORD_CHANGE_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_SEARCH_REQUEST = 'USER_SEARCH_REQUEST'
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS'
export const USER_SEARCH_FAIL = 'USER_SEARCH_FAIL'
