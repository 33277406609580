export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST'
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS'
export const VIDEO_LIST_FAIL = 'VIDEO_LIST_FAIL'

export const VIDEO_CREATE_REQUEST = 'VIDEO_CREATE_REQUEST'
export const VIDEO_CREATE_SUCCESS = 'VIDEO_CREATE_SUCCESS'
export const VIDEO_CREATE_FAIL = 'VIDEO_CREATE_FAIL'
export const VIDEO_CREATE_RESET = 'VIDEO_CREATE_RESET'

export const VIDEO_UPDATE_REQUEST = 'VIDEO_UPDATE_REQUEST'
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS'
export const VIDEO_UPDATE_FAIL = 'VIDEO_UPDATE_FAIL'

export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST'
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS'
export const VIDEO_DELETE_FAIL = 'VIDEO_DELETE_FAIL'
