export const CALENDAR_EVENT_LIST_REQUEST = 'CALENDAR_EVENT_LIST_REQUEST'
export const CALENDAR_EVENT_LIST_SUCCESS = 'CALENDAR_EVENT_LIST_SUCCESS'
export const CALENDAR_EVENT_LIST_FAIL = 'CALENDAR_EVENT_LIST_FAIL'

export const CALENDAR_EVENT_CREATE_REQUEST = 'CALENDAR_EVENT_CREATE_REQUEST'
export const CALENDAR_EVENT_CREATE_SUCCESS = 'CALENDAR_EVENT_CREATE_SUCCESS'
export const CALENDAR_EVENT_CREATE_FAIL = 'CALENDAR_EVENT_CREATE_FAIL'

export const CALENDAR_EVENT_LIST_BYID_REQUEST = 'CALENDAR_EVENT_CREATE_LIST_BYID_REQUEST'
export const CALENDAR_EVENT_LIST_BYID_SUCCESS = 'CALENDAR_EVENT_LIST_BYID_SUCCESS'
export const CALENDAR_EVENT_LIST_BYID_FAIL = 'CALENDAR_EVENT_LIST_BYID_FAIL'
