export const LESSSON_TEXT_LIST_REQUEST = 'TEXT_LIST_REQUEST'
export const TEXT_LIST_SUCCESS = 'TEXT_LIST_SUCCESS'
export const TEXT_LIST_FAIL = 'TEXT_LIST_FAIL'

export const TEXT_CREATE_REQUEST = 'TEXT_CREATE_REQUEST'
export const TEXT_CREATE_SUCCESS = 'TEXT_CREATE_SUCCESS'
export const TEXT_CREATE_FAIL = 'TEXT_CREATE_FAIL'
export const TEXT_CREATE_RESET = 'TEXT_CREATE_RESET'

export const TEXT_UPDATE_REQUEST = 'TEXT_UPDATE_REQUEST'
export const TEXT_UPDATE_SUCCESS = 'TEXT_UPDATE_SUCCESS'
export const TEXT_UPDATE_FAIL = 'TEXT_UPDATE_FAIL'

export const TEXT_DELETE_REQUEST = 'TEXT_DELETE_REQUEST'
export const TEXT_DELETE_SUCCESS = 'TEXT_DELETE_SUCCESS'
export const TEXT_DELETE_FAIL = 'TEXT_DELETE_FAIL'
