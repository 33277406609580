const nav = [
  {
    label: 'All courses',
    link: '/courses'
  },
  {
    label: 'My courses & collections',
    link: '/courses/my-courses'
  },
  {
    label: 'Users collections',
    link: '/courses/users'
  },
  {
    label: 'Saved collections',
    link: '/courses/saved-collection'
  }
]
export default nav
