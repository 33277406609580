const data = [
  {
    activities: [
      {
        task: 'take a new course',
        time: '04:00 pm',
        id: 1
      },
      {
        task: 'Perform an art',
        time: '01:00 am',
        id: 2
      },
      {
        task: 'Perform an art',
        time: '',
        id: 3
      },
      {
        task: 'Perform an art',
        time: '',
        id: 4
      }
    ],
    date: '2021-6-16'
  },
  {
    activities: [
      {
        task: 'take a new course',
        time: '04:00 pm',
        id: 5
      },
      {
        task: 'Perform an art',
        time: '01:00 am',
        id: 6
      },
      {
        task: 'Perform an art',
        time: '',
        id: 7
      }
    ],
    date: '2021-6-17'
  },
  {
    activities: [
      {
        task: 'Perform an art',
        time: '',
        id: 8
      },
      {
        task: 'Perform an art',
        time: '',
        id: 9
      }
    ],
    date: '2021-6-18'
  }
]

export default data
