export const CommunityPageNewsData = {
  search: 'Search…',
  addNews: 'Add news',
  text22: 'Discover more of what matters to you',
  farming: 'Farming',
  people: 'People',
  nature: 'Nature',
  carsIndustry: 'Cars industry',
  mediaNews: 'Media news',
  seeAllTopics: 'See all topics'
}
