const courseType = [
  {
    id: 'l0',
    name: 'Usual course',
    desc: 'Online course with lessons and test',
    type: 'usual',
    img: 'farmer.svg'
  },
  {
    id: '1',
    name: 'Live experience',
    desc: 'Live lesson session with your users',
    type: 'live',
    img: 'live-exp.png'
  }
]
export default courseType
