export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST'
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS'
export const GROUP_LIST_FAIL = 'GROUP_LIST_FAIL'

export const GROUP_SEARCH_REQUEST = 'GROUP_SEARCH_REQUEST'
export const GROUP_SEARCH_SUCCESS = 'GROUP_SEARCH_SUCCESS'
export const GROUP_SEARCH_FAIL = 'GROUP_SEARCH_FAIL'

export const GROUP_CREATE_REQUEST = 'GROUP_CREATE_REQUEST'
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS'
export const GROUP_CREATE_FAIL = 'GROUP_CREATE_FAIL'
export const GROUP_CREATE_RESET = 'GROUP_CREATE_RESET'

export const GROUP_LIST_BYID_REQUEST = 'GROUP_LIST_BYID_REQUEST'
export const GROUP_LIST_BYID_SUCCESS = 'GROUP_LIST_BYID_SUCCESS'
export const GROUP_LIST_BYID_FAIL = 'GROUP_LIST_BYID_FAIL'

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST'
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS'
export const GROUP_UPDATE_FAIL = 'GROUP_UPDATE_FAIL'

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST'
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS'
export const GROUP_DELETE_FAIL = 'GROUP_DELETE_FAIL'

export const GROUP_FOLLOW_REQUEST = 'GROUP_FOLLOW_REQUEST'
export const GROUP_FOLLOW_SUCCESS = 'GROUP_FOLLOW_SUCCESS'
export const GROUP_FOLLOW_FAIL = 'GROUP_FOLLOW_FAIL'

export const USER_GROUP_LIST_REQUEST = 'USER_GROUP_LIST_REQUEST'
export const USER_GROUP_LIST_SUCCESS = 'USER_GROUP_LIST_SUCCESS'
export const USER_GROUP_LIST_FAIL = 'USER_GROUP_LIST_FAIL'

export const USER_GROUP_SEARCH_REQUEST = 'USER_GROUP_SEARCH_REQUEST'
export const USER_GROUP_SEARCH_SUCCESS = 'USER_GROUP_SEARCH_SUCCESS'
export const USER_GROUP_SEARCH_FAIL = 'USER_GROUP_SEARCH_FAIL'
