export const COMMUNITY_LIST_REQUEST = 'COMMUNITY_LIST_REQUEST'
export const COMMUNITY_LIST_SUCCESS = 'COMMUNITY_LIST_SUCCESS'
export const COMMUNITY_LIST_FAIL = 'COMMUNITY_LIST_FAIL'

export const COMMUNITY_SEARCH_REQUEST = 'COMMUNITY_SEARCH_REQUEST'
export const COMMUNITY_SEARCH_SUCCESS = 'COMMUNITY_SEARCH_SUCCESS'
export const COMMUNITY_SEARCH_FAIL = 'COMMUNITY_SEARCH_FAIL'

export const USER_COMMUNITY_LIST_REQUEST = 'USER_COMMUNITY_LIST_REQUEST'
export const USER_COMMUNITY_LIST_SUCCESS = 'USER_COMMUNITY_LIST_SUCCESS'
export const USER_COMMUNITY_LIST_FAIL = 'USER_COMMUNITY_LIST_FAIL'

export const USER_COMMUNITY_SEARCH_REQUEST = 'USER_COMMUNITY_SEARCH_REQUEST'
export const USER_COMMUNITY_SEARCH_SUCCESS = 'USER_COMMUNITY_SEARCH_SUCCESS'
export const USER_COMMUNITY_SEARCH_FAIL = 'USER_COMMUNITY_SEARCH_FAIL'

export const COMMUNITY_CREATE_REQUEST = 'COMMUNITY_CREATE_REQUEST'
export const COMMUNITY_CREATE_SUCCESS = 'COMMUNITY_CREATE_SUCCESS'
export const COMMUNITY_CREATE_FAIL = 'COMMUNITY_CREATE_FAIL'
export const COMMUNITY_CREATE_RESET = 'COMMUNITY_CREATE_RESET'

export const COMMUNITY_JOIN_REQUEST = 'COMMUNITY_JOIN_REQUEST'
export const COMMUNITY_JOIN_SUCCESS = 'COMMUNITY_JOIN_SUCCESS'
export const COMMUNITY_JOIN_FAIL = 'COMMUNITY_JOIN_FAIL'

export const COMMUNITY_VISIT_REQUEST = 'COMMUNITY_VISIT_REQUEST'
export const COMMUNITY_VISIT_SUCCESS = 'COMMUNITY_VISIT_SUCCESS'
export const COMMUNITY_VISIT_FAIL = 'COMMUNITY_VISIT_FAIL'

export const COMMUNITY_UPDATE_REQUEST = 'COMMUNITY_UPDATE_REQUEST'
export const COMMUNITY_UPDATE_SUCCESS = 'COMMUNITY_UPDATE_SUCCESS'
export const COMMUNITY_UPDATE_FAIL = 'COMMUNITY_UPDATE_FAIL'

export const COMMUNITY_DELETE_REQUEST = 'COMMUNITY_DELETE_REQUEST'
export const COMMUNITY_DELETE_SUCCESS = 'COMMUNITY_DELETE_SUCCESS'
export const COMMUNITY_DELETE_FAIL = 'COMMUNITY_DELETE_FAIL'
