export const ENROLL_LIST_REQUEST = 'ENROLL_LIST_REQUEST'
export const ENROLL_LIST_SUCCESS = 'ENROLL_LIST_SUCCESS'
export const ENROLL_LIST_FAIL = 'ENROLL_LIST_FAIL'

export const ENROLL_CREATE_REQUEST = 'ENROLL_CREATE_REQUEST'
export const ENROLL_CREATE_SUCCESS = 'ENROLL_CREATE_SUCCESS'
export const ENROLL_CREATE_FAIL = 'ENROLL_CREATE_FAIL'
export const ENROLL_CREATE_RESET = 'ENROLL_CREATE_RESET'

export const ENROLL_UPDATE_REQUEST = 'ENROLL_UPDATE_REQUEST'
export const ENROLL_UPDATE_SUCCESS = 'ENROLL_UPDATE_SUCCESS'
export const ENROLL_UPDATE_FAIL = 'ENROLL_UPDATE_FAIL'

export const ENROLL_DELETE_REQUEST = 'ENROLL_DELETE_REQUEST'
export const ENROLL_DELETE_SUCCESS = 'ENROLL_DELETE_SUCCESS'
export const ENROLL_DELETE_FAIL = 'ENROLL_DELETE_FAIL'
