export const TEST_CREATE_REQUEST = 'TEST_CREATE_REQUEST'
export const TEST_CREATE_SUCCESS = 'TEST_CREATE_SUCCESS'
export const TEST_CREATE_FAIL = 'TEST_CREATE_FAIL'
export const TEST_CREATE_RESET = 'TEST_CREATE_RESET'

export const TEST_QUESTION_LIST_REQUEST = 'TEST_QUESTION_LIST_REQUEST'
export const TEST_QUESTION_LIST_SUCCESS = 'TEST_QUESTION_LIST_SUCCESS'
export const TEST_QUESTION_LIST_FAIL = 'LESSON_LIST_FAIL'

export const TEST_QUESTION_EDIT_REQUEST = 'TEST_QUESTION_EDIT_REQUEST'
export const TEST_QUESTION_EDIT_SUCCESS = 'TEST_QUESTION_EDIT_SUCCESS'
export const TEST_QUESTION_EDIT_FAIL = 'LESSON_EDIT_FAIL'
