export const LESSON_LIST_REQUEST = 'LESSON_LIST_REQUEST'
export const LESSON_LIST_SUCCESS = 'LESSON_LIST_SUCCESS'
export const LESSON_LIST_FAIL = 'LESSON_LIST_FAIL'

export const LESSON_CREATE_REQUEST = 'LESSON_CREATE_REQUEST'
export const LESSON_CREATE_SUCCESS = 'LESSON_CREATE_SUCCESS'
export const LESSON_CREATE_FAIL = 'LESSON_CREATE_FAIL'
export const LESSON_CREATE_RESET = 'LESSON_CREATE_RESET'

export const LESSON_UPDATE_REQUEST = 'LESSON_UPDATE_REQUEST'
export const LESSON_UPDATE_SUCCESS = 'LESSON_UPDATE_SUCCESS'
export const LESSON_UPDATE_FAIL = 'LESSON_UPDATE_FAIL'

export const LESSON_DELETE_REQUEST = 'LESSON_DELETE_REQUEST'
export const LESSON_DELETE_SUCCESS = 'LESSON_DELETE_SUCCESS'
export const LESSON_DELETE_FAIL = 'LESSON_DELETE_FAIL'
