export const library = [
  {
    category: 'farming',
    title: 'Business collection',
    img: '/img/man-cap.svg'
  },
  {
    category: 'farming',
    title: 'Business collection for beginners',
    img: '/img/man-cap.svg'
  }
]

export const collections = [
  {
    category: 'farming',
    title: 'Business collection for beginners',
    img: '/img/man-cap.svg'
  }
]

export const groupCollection = [
  {
    image: '/img/user.png',
    name: 'My favorite collection',
    active: false
  },
  {
    image: '/img/user2.png',
    name: 'Travelling collection',
    active: true
  },
  {
    image: '/img/user-2.png',
    name: 'Business Collection #1',
    active: true
  },
  {
    image: '/img/user.png',
    name: 'Files for beginners',
    active: false
  },
  {
    image: '/img/user-2.png',
    name: 'Farming Collection',
    active: false
  },
  {
    image: '/img/user.png',
    name: 'Be Creative!',
    active: false
  }
]

export const articles = [
  {
    title: 'How to make business in 2020',
    category: 'Business',
    img: '/img/mountain.svg'
  },
  {
    title: 'How to be productive in this year and find yourslef',
    category: 'Business',
    img: '/img/lake.svg'
  },
  {
    title: 'How to make business in 2021',
    category: 'Business',
    img: '/img/lake.svg'
  }
]

export const videos = [
  {
    title: 'How to be productive in this year and find yourslef',
    category: 'Business',
    img: '/img/man.svg'
  },
  {
    title: 'How to make business in 2021',
    category: 'Business',
    img: '/img/sky.svg'
  },
  {
    title: 'How to make business in 2021mm',
    category: 'Business',
    img: '/img/mountain.svg'
  }
]

export const farming = [
  {
    category: 'farming',
    title: 'Business collection',
    users: 365,
    img: '/img/mountain.svg'
  },
  {
    category: 'farming',
    title: 'Business collection',
    users: 365,
    img: '/img/man.svg'
  },
  {
    category: 'farming',
    title: 'Business collection',
    users: 365,
    img: '/img/mountain.svg'
  },
  {
    category: 'farming',
    title: 'Business collection',
    users: 365,
    img: '/img/man.svg'
  },
  {
    category: 'farming',
    title: 'Business collection',
    users: 365,
    img: '/img/mountain.svg'
  },
  {
    category: 'farming',
    title: 'Business collection',
    users: 365,
    img: '/img/man.svg'
  }
]

export const nav = [{
  label: 'All files',
  link: '/library'
}, {
  label: 'My library & collections',
  link: '/library/collection'
}, {
  label: 'Users collection',
  link: '/library/collection/users'
}, {
  label: 'Saved collection',
  link: '/library/collection/saved'
}]
